exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-featured-post-js": () => import("./../../../src/pages/FeaturedPost.js" /* webpackChunkName: "component---src-pages-featured-post-js" */),
  "component---src-pages-footer-js": () => import("./../../../src/pages/Footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-head-js": () => import("./../../../src/pages/head.js" /* webpackChunkName: "component---src-pages-head-js" */),
  "component---src-pages-header-js": () => import("./../../../src/pages/Header.js" /* webpackChunkName: "component---src-pages-header-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-featured-post-js": () => import("./../../../src/pages/MainFeaturedPost.js" /* webpackChunkName: "component---src-pages-main-featured-post-js" */),
  "component---src-pages-main-js": () => import("./../../../src/pages/Main.js" /* webpackChunkName: "component---src-pages-main-js" */),
  "component---src-pages-sidebar-js": () => import("./../../../src/pages/Sidebar.js" /* webpackChunkName: "component---src-pages-sidebar-js" */)
}

